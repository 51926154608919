<template>
  <div>
    <div ref="wdrComponent"></div>
  </div>
</template>

<script>
import { Pivot } from "@webdatarocks/vue-webdatarocks";

export default {
  name: 'MeterReading',
  data(){
    return {
      report: null
    }
  },
  mounted() {

    const self = this;
    this.report = new WebDataRocks({
      container: this.$refs.wdrComponent,
      toolbar: false,
      height: 500,
      width: "100%",
      report: {
        dataSource: {
          "dataSourceType": "csv",
          "filename": "https://cdn.webdatarocks.com/data/data.csv"
        },
        "slice": {
          "rows": [
            {
              "uniqueName": "Country"
            },
            {
              "uniqueName": "Category"
            }
          ],
          "columns": [
            {
              "uniqueName": "Business Type"
            },
            {
              "uniqueName": "Measures"
            }
          ],
          "measures": [
            {
              "uniqueName": "Price",
              "aggregation": "sum"
            }
          ]
        },
        "conditions": [
          {
            "formula": "#value > 2000000",
            "measure": "Price",
            "format": {
              "backgroundColor": "#0598df",
              "color": "#FFFFFF",
              "fontFamily": "Arial",
              "fontSize": "12px"
            }
          }
        ]
      }
    });
    $('.wdr-credits').remove();
    setTimeout(()=>{
      $('.wdr-credits').remove();
    },99);

  },

  methods:{
    remove_branding(){
      try {
        $('.wdr-credits').remove();
        // const brand_ele = document.getElementsByClassName("wdr-credits");
        // brand_ele.removeChild();
        //class="wdr-ui-element wdr-ui wdr-ui-label wdr-credits"
        // alert('remove_branding');
      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>


</style>
